import './form_toggle.sass';

(function () {
  const toggleMap = {};

  function setupToggles() {
    document.querySelectorAll('[data-toggle-id]').forEach((el) => {
      const toggleId = el.getAttribute('data-toggle-id') || el.id;
      const toggleFor = el.getAttribute('data-toggle-for');

      if (toggleMap[toggleId]) {
        toggleMap[toggleId].for = toggleMap[toggleId].for || toggleFor;
      } else {
        toggleMap[toggleId] = {
          for: toggleFor,
        };
      }

      el.addEventListener('click', (e) => {
        toggle(toggleId);
        e.preventDefault();
      });
    });
  }

  function toggle(id) {
    const data = toggleMap[id];

    document.querySelectorAll(data.for).forEach((el) => {
      if (el.classList.contains('shown')) {
        el.classList.remove('shown');
        el.classList.add('hidden');
      } else if (el.classList.contains('hidden')) {
        el.classList.add('shown');
        el.classList.remove('hidden');
      }
    });
  }

  window.addEventListener('DOMContentLoaded', setupToggles);
})();
