import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/image-editor/dist/style.css';

import AwsS3Multipart from '@uppy/aws-s3-multipart';
import Dashboard from '@uppy/dashboard';
import Dropbox from '@uppy/dropbox';
import GoogleDrive from '@uppy/google-drive';
import ImageEditor from '@uppy/image-editor';
import OneDrive from '@uppy/onedrive';
import Uppy from '@uppy/core';
import { post } from '../../helpers/requests';

function initUploader() {
  const triggersSelector = '.vanguard-uploader-trigger';
  const triggers = document.querySelectorAll(triggersSelector);

  if (triggers.length > 0) {
    const companionUrl = pretzelConfig.upload.companionUrl;
    const companionAllowedHosts = pretzelConfig.upload.allowedOrigins.split(',');

    for (let i = 0; i < triggers.length; i++) {
      const el = triggers[i];
      const config = JSON.parse(el.getAttribute('data-upload-config'));

      let allowedFileTypes = null;
      if (config.type === 'image') {
        allowedFileTypes = ['image/*'];
      } else if (config.type === 'audio') {
        allowedFileTypes = ['audio/*', '.aiff', '.aif'];
      } else if (config.type === 'lossless') {
        allowedFileTypes = ['audio/wav','audio/x-wav','audio/flac','audio/x-flac'];
      } else if (config.type === 'pdf') {
        allowedFileTypes = ['application/pdf'];
      }

      let autoOpenFileEditor = false;
      const metaFields = [];

      if (config.fields) {
        autoOpenFileEditor = true;

        config.fields.forEach((f) => {
          metaFields.push({ id: `vanguard-${f.id}`, name: f.name, placeholder: f.placeholder });
        });
      }

      const uppy = new Uppy({
        debug: true,
        autoProceed: false,
        meta: {
          pretzelUploadToken: config.uploadToken,
        },
        restrictions: {
          maxNumberOfFiles: config.maxFiles || null,
          allowedFileTypes,
        },
        onBeforeUpload: (files) => {
          Object.values(files).forEach(file => {
            uppy.setFileMeta(file.id, { name: file.name.split('.')[0].replace(/[^\w\s ]/g, '') + '.' + file.extension });
          })
        },
      })
      .use(Dashboard, {
        browserBackButtonClose: false,
        showProgressDetails: true,
        autoOpenFileEditor,
        metaFields,
        inline: config.openInline === true,
        target: config.openInline === true ? el : 'body',
        proudlyDisplayPoweredByUppy: false,
        doneButtonHandler: (e) => {
          // Note: Reference to Dashboard plugin instance has to be found this way as although uppy documentation
          //       says that "this" should refer to the instance of the current plugin it does not and is an object
          //       with reference to the button click event instead.
          let _this = uppy.getPlugin('Dashboard');
          _this.uppy.reset();

          if (config.openInline !== true) {
            // Note: Page reloading will be handled as part of modal closed event.
            _this.requestCloseModal();
          }
          else if (config.reloadPageOnComplete === true) {
              reloadPage();
          }
        },
        onRequestCloseModal: () => {
          let _this = uppy.getPlugin('Dashboard');
          _this.closeModal();
        }
      })
      .use(GoogleDrive, { target: Dashboard, companionUrl, companionAllowedHosts })
      .use(Dropbox, { target: Dashboard, companionUrl, companionAllowedHosts })
      .use(OneDrive, { target: Dashboard, companionUrl, companionAllowedHosts })
      .use(AwsS3Multipart, { companionUrl, companionAllowedHosts })
      .use(ImageEditor, {
        target: Dashboard,
        id: 'ImageEditor',
        quality: 0.8,
        cropperOptions: {
          viewMode: 1,
          background: false,
          autoCropArea: 1,
          responsive: true,
          croppedCanvasOptions: {},
        },
        actions: {
          revert: true,
          rotate: true,
          granularRotate: true,
          flip: true,
          zoomIn: true,
          zoomOut: true,
          cropSquare: true,
          cropWidescreen: true,
          cropWidescreenVertical: true,
        },
      });

      uppy.on('upload-progress', (file) => {
        const state = uppy.getState().files[file.id];
        if (state.s3Multipart?.key) {
          initializeFile(file.id, state.s3Multipart.key, state.meta, config.attachable, config);
        }
      });

      uppy.on('complete', (result) => {
        result.successful.forEach((f) => {
          if (hasInitialized(f.id)) {
            finalizeFile(f.id, config);
          } else {
            const key = f.uploadURL.split('/').slice(3).join('/');
            initializeFile(f.id, key, f.meta, config.attachable, config).then((blobId) => {
              if (blobId) {
                finalizeFile(f.id, config);
              } else {
                console.error('Failed to initialize file!');
              }
            });
          }
        });

        if (config.openInline !== true && config.reloadPageOnComplete === true) {
          uppy.on('dashboard:modal-closed', () => {
            reloadPage();
          });
        }
      });


      if (config.openInline !== true) {
        el.addEventListener('click', () => {
          uppy.getPlugin('Dashboard').openModal();
        });
      }
    }
  }

  const initializedFiles = {};

  function hasInitialized(fileId) {
    return !!initializedFiles[fileId];
  }

  async function initializeFile(fileId, key, meta, attachable, config) {
    if (initializedFiles[fileId]) {
      return;
    }

    initializedFiles[fileId] = true;
    try {
      const data = {
        key,
        mimeType: meta.type,
        fileName: meta.name,
        attachable,
      };

      if (config.fields) {
        for (const f of config.fields) {
          data[f.id] = meta[`vanguard-${f.id}`];
        }
      }

      const response = await post('/files/uploading', data);
      if (response.success) {
        initializedFiles[fileId] = response.id;
      } else {
        initializedFiles[fileId] = false;
      }
    } catch (e) {
      initializedFiles[fileId] = false;
      throw e;
    }
    return initializedFiles[fileId];
  }

  async function finalizeFile(fileId, config) {
    if (!initializedFiles[fileId]) {
      console.error('Failed to initialize file entry!', { fileId });
      return;
    }

    const blobId = initializedFiles[fileId];
    if (blobId) {
      const response = await post(`/files/${blobId}/upload_complete`);
      if (config.createRecording) {
        const response = await post(`/content_ids/${blobId}/create_recording`);
      }
      console.log('Finished up file!', fileId, response);
    }
  }

  async function reloadPage()
  {
    window.location.reload();
  }
}

$(initUploader);
