import $ from 'jquery';
import validate from 'jquery-validation';

$(() => {
  $(':submit').removeAttr('data-disable-with');
  let totalMechanicalOwnershipValue;
  let totalSyncOwnershipValue;
  const maxCompositionIds = 5;
  const compositionInputPrefix = 'phoenix_licensor_submissions_composition_data';
  const NoSociety = '100';

  const $compositionIdentifiersNewRow = $('.compostion_identifiers_row').first();
  const deleteCompositionIdRowBtn = `<a role="button" class='remove_identifier_btn'>Delete</a>`;

  $('#btn_add_composition_identifier').on('click', function () {
    if ($('.compostion_identifiers_row').length <= maxCompositionIds);
    {
      let clonedRow = $compositionIdentifiersNewRow.clone();
      $(clonedRow).find('input')[0].value = '';
      clonedRow.insertAfter($('.compostion_identifiers_row').last());
      clonedRow.append(deleteCompositionIdRowBtn);

      // there are only 5 types of "composition identifier" so hide add button when 5 have been added.
      if ($('.compostion_identifiers_row').length === 5) {
        $('#btn_add_composition_identifier').hide();
      }
    }
  });
  $(document).on('change', '.composition_identifiers_select', function () {
    const inputSuffix = this.value;
    const inputToSetAttsOn = $(this).next();
    inputToSetAttsOn.attr('id', `${compositionInputPrefix}_${inputSuffix}`);
    inputToSetAttsOn.attr('name', `${compositionInputPrefix}[${inputSuffix}]`);
  });

  $(document).on('click', '.remove_identifier_btn', function () {
    $('#btn_add_composition_identifier').show();
    $(this).parent().remove();
  });

  $('.phoenix_licensor_submissions_composition_data').each((i, el) => {
    setupMissingCompositionForm(el);
  });

  function setupMissingCompositionForm(el) {
    const shares = [{ controlled: 'true' }];
    const sharesDisplay = el.querySelector('.shares_info_section');
    const licensorName = sharesDisplay.getAttribute('data-licensor-name');

    $(sharesDisplay).on('click', '.add-share-trigger', (e) => {
      shares.push({ controlled: 'true' });
      renderShares();
    });

    $(sharesDisplay).on('input', (e) => {
      const targetEl = e.target;
      const parent = $(targetEl).parents('[data-share-index]');
      const shareIndex = parseInt(parent.attr('data-share-index'));
      const property = targetEl.name.slice(57, -1);
      if (property === 'society' || property === 'role') {
        shares[shareIndex][property] = {
          value: targetEl.value,
          text: targetEl.selectedOptions[0].innerText,
        };
      } else {
        shares[shareIndex][property] = targetEl.value;
      }

      renderTotalsDisplay();
    });

    renderShares();

    function renderShares() {
      console.log('Rendering Shares:', shares);
      let results = [];
      for (let i = 0; i < shares.length; i++) {
        const share = shares[i];
        results.push(renderShare(share, i));
      }

      const header = `<legend><span>Composition shares ownership</span></legend>`;
      const sharesDetails = results.join(' <hr> ');
      const addShare = `<button type="button" class="add-share-trigger btn_add_another">Add another share</button>`;
      const displayBody = `${header} ${sharesDetails} ${addShare}`;
      sharesDisplay.innerHTML = displayBody;
      $(sharesDisplay).find('.searchable-select-input').select2();
      // hide the default select2 dropdown arrow and use ours
      $('b[role="presentation"]').hide();
      $('.select2-selection__arrow').append('<i class="fawkes-select"></i>');
      renderTotalsDisplay();
    }

    function renderShare(share, index) {
      let result = `<ol data-share-index="${index}">`;
      result += renderWrapper(
        'Owner name',
        `<input type="text" name="phoenix_licensor_submissions_composition_data[shares][${index}][name]" placeholder="Enter owner name" data-validation-msg="Please enter an owner name" value="${sanitize(
          share.name,
        )}" />`,
        true,
      );
      result += renderWrapper(
        'Society <br /><span style="font-size:0.8rem; color:#515555;" title="required">Select "NS" for No Society</span>',
        `<select name="phoenix_licensor_submissions_composition_data[shares][${index}][society]" class="searchable-select-input" placeholder="Please select" data-validation-msg="Please select a society" data-ajax-url="/societies/all_options"><option value="" label=" "></option>${dynamicSelectedOption(
          share.society,
        )}</select>`,
        false,
        'searchable_select',
      );
      result += renderWrapper(
        'IPI number',
        `<input type="text" name="phoenix_licensor_submissions_composition_data[shares][${index}][ipi]" placeholder="Enter IPI number" data-validation-msg="Please enter an IPI number" class="required-if" value="${sanitize(
          share.ipi,
        )}" />`,
        false,
      );

      result += renderWrapper(
        'Role',
        `<select name="phoenix_licensor_submissions_composition_data[shares][${index}][role]" class="searchable-select-input" data-validation-msg="Please select a role" data-ajax-url="/interested_party_roles/all_options"><option value="" label=" "></option>${dynamicSelectedOption(
          share.role,
        )}</select>`,
        true,
        'searchable_select',
      );
      result += renderWrapper(
        'Mechanical ownership &#37',
        `<input type="number" min="0" max="100" name="phoenix_licensor_submissions_composition_data[shares][${index}][mechanical_ownership_amount]" data-validation-msg="Please enter a mechanical ownership percentage" placeholder= "Enter amount" value="${sanitize(
          share.mechanical_ownership_amount,
        )}" />`,
        true,
      );
      result += renderWrapper(
        'Sync ownership &#37',
        `<input type="number" min="0" max="100" name="phoenix_licensor_submissions_composition_data[shares][${index}][sync_ownership_amount]" data-validation-msg="Please enter a sync ownership percentage" placeholder= "Enter amount" value="${sanitize(
          share.sync_ownership_amount,
        )}" />`,
        true,
      );
      result += renderWrapper(
        sanitize(`Share controlled by:
         ${licensorName}?`),
        `<select name="phoenix_licensor_submissions_composition_data[shares][${index}][controlled]" class="yes-no-select"><option value="true" ${selectIf(
          share.controlled,
          'true',
        )}>Yes</option><option value="false" ${selectIf(share.controlled, 'false')}>No</option></select>`,
        true,
      );

      result += '</ol>';

      return result;
    }

    function selectIf(value, target) {
      if (value === target) {
        return 'selected="selected"';
      } else {
        return '';
      }
    }

    function dynamicSelectedOption(selectedOption) {
      if (selectedOption) {
        return `<option value="${selectedOption.value}" selected="selected">${selectedOption.text}</option>`;
      } else {
        return '';
      }
    }

    function renderTotalsDisplay() {
      const totals = {
        mechanical: 0,
        sync: 0,
      };
      const controlledTotals = {
        mechanical: 0,
        sync: 0,
      };
      for (const share of shares) {
        totals.mechanical += parseFloat(share.mechanical_ownership_amount) || 0;
        totals.sync += parseFloat(share.sync_ownership_amount) || 0;
        if (share.controlled === 'true') {
          controlledTotals.mechanical += parseFloat(share.mechanical_ownership_amount) || 0;
          controlledTotals.sync += parseFloat(share.sync_ownership_amount) || 0;
        }
        totalMechanicalOwnershipValue = totals.mechanical;
        totalSyncOwnershipValue = totals.sync;
      }

      const inputTotalsDisplay = document.querySelector('.input-shares-totals');
      const controlledTotalsDisplay = document.querySelector('.controlled-shares-totals');

      inputTotalsDisplay.innerHTML = `
<dl class="inline-flex">
<dt>Mechanical</dt>
<dd id="total-mechanical-percentage">${totals.mechanical}</dd>
<dt>Sync</dt>
<dd id="total-sync-percentage">${totals.sync}</dd>
</dl>`;
      controlledTotalsDisplay.innerHTML = `
<dl class="inline-flex">
<dt>Mechanical</dt>
<dd>${controlledTotals.mechanical}</dd>
<dt>Sync</dt>
<dd>${controlledTotals.sync}</dd>
</dl>
`;
    }

    function renderWrapper(label, input, required, additionalClasses) {
      const requiredHtml = required ? '<abbr title="required"></abbr>' : '';
      return `
<li class="input ${required ? 'required' : ''} ${additionalClasses}">
  <label class="label">${label}${requiredHtml}</label>
  ${input}
</li>`;
    }
  }

  let sanitizer;

  function sanitize(str) {
    if (!str) {
      return '';
    }

    if (!sanitizer) {
      sanitizer = document.createElement('div');
    }
    sanitizer.innerHTML = '';
    sanitizer.innerText = str;
    return sanitizer.innerHTML;
  }

  $(document).on('submit', '#new_phoenix_licensor_submissions_composition_data', function (e) {
    // add validation rule to all inputs in the form except those with the css class .required-if (i.e. IPI number which is only required if Society != NS)
    $('#new_phoenix_licensor_submissions_composition_data')
      .find('input:not(.required-if)')
      .each(function () {
        let validationMessage = $(this).data('validationMsg') ?? 'Please complete this field';
        $(this).rules('add', {
          required: true,
          messages: {
            required: validationMessage,
          },
        });
      });

    // now add the conditional validation on IPI number field for each of the dynamically added shares.
    let totalSharesAdded = $('fieldset.shares_info_section ol').length;
    for (let index = 0; index < totalSharesAdded; index++) {
      let validationMessageForIPINumber = 'Please enter an IPI number';
      $(`[name="phoenix_licensor_submissions_composition_data[shares][${index}][ipi]"]`).rules('add', {
        required: function (element) {
          return (
            $(`[name="phoenix_licensor_submissions_composition_data[shares][${index}][society]"]`).val() !== NoSociety
          );
        },
        messages: {
          required: validationMessageForIPINumber,
        },
      });
    }
    if ($('#new_phoenix_licensor_submissions_composition_data').validate().form()) {
      if (totalMechanicalOwnershipValue !== 100 || totalSyncOwnershipValue !== 100) {
        $('#total-sync-percentage').after(
          `<span style="color:red;">We require 100 &#37 Total ownership to be inputted for both mechanical and sync</span>`,
        );
        return false;
      }
      return true;
    } else {
      return false;
    }
  });

  $('#new_phoenix_licensor_submissions_composition_data').validate({
    errorElement: 'span',
    focusInvalid: false,
    invalidHandler: function (form, validator) {
      if (!validator.numberOfInvalids()) return;

      $('html, body').animate(
        {
          scrollTop: $(validator.errorList[0].element).offset().top - 30,
        },
        500,
      );
    },
  });
});
