$(function() {
  let flashMessages = $('.flashes .flash');
  
  var flashMessageTimeout = setTimeout(function () { 
    hideFlash(flashMessages) 
  }, 4000);

  flashMessages.on('click', '.flash_close', function(event) {
    clearTimeout(flashMessageTimeout);    
    hideFlash($(event.delegateTarget));
  });
  
  flashMessages.on('click', '.flash_icon', function(event) {
    clearTimeout(flashMessageTimeout);
  });
  
  function hideFlash(el) {
    el.fadeOut(800, function () {
      $(this).remove();
    });
  };
})
