import * as React from "react";
import {useState} from "react";
import Select from 'react-select'

const SocialClearanceIdentifiersSection = ({platforms, identifiers: originalIdentifiers, showFullForm}) => {

  const platformLookup = platforms.reduce((acc, platform) => { acc[platform.value] = platform; return acc }, {});
  const [identifiers, setIdentifiers] = useState(originalIdentifiers?.length > 0 ? originalIdentifiers : [{platform: 'youtube_channel', platform_identifier: '', aegis_id: '', status: ''}]);

  const addIdentifier = () => {
    setIdentifiers(identifiers.concat({platform: 'youtube_channel', platform_identifier: '', aegis_id: '', status: ''}));
  }

  let submittable = true;
  const renderIdentifierForm = (identifier, index) => {
    const inputNamePrefix = `social_clearance[social_clearance_identifiers_attributes][${index}]`;
    const inputIdPrefix = `social_clearance_social_clearance_identifiers_attributes_${index}`;

    const updateIdentifier = (property, value, property2, value2) => {
      const updatedIdentifier = {
        ...identifier,
        [property]: value,
        [property2]: value2,
      }
      const newIdentifiers = [...identifiers];
      newIdentifiers.splice(index, 1, updatedIdentifier)
      setIdentifiers(newIdentifiers);
    }

    const updateHandler = (property, prop = 'value') => {
      return (event) => updateIdentifier(property, event.target[prop]);
    };
    const updateIdHandler = (property) => {
      return async (event) => {
        const theInput = event.target.value;
        const id = extractYouTubeId(event.target.value);
        if (id) {
          updateIdentifier(property, id, `${property}Message`, '')
        } else if (event.target.value.startsWith('https://')) {
          try {
            const detailsResponse = await fetch(`/social_clearances/get_canonical_from_url.json?url=${theInput}`)
            const detailsJson = await detailsResponse.json();
            if (detailsJson?.canonical){
              const canonicalId = extractYouTubeId(detailsJson.canonical);
              if (canonicalId) {
                updateIdentifier(property, canonicalId, `${property}Message`, `Retrieved ID From YouTube URL: ${theInput}`)
              } else {
                updateIdentifier(property, theInput, `${property}Message`, `Could not retrieve ID from YouTube URL: ${theInput}`)
              }
            }
          } catch (e) {
            console.error(e);
            updateIdentifier(property, theInput, `${property}Message`, `Could not retrieve ID from YouTube URL: ${theInput}`)
          }
        } else {
          updateIdentifier(property, theInput, `${property}Message`, '')
        }
      }
    };

    let pastedIdentifier
    const extractYouTubeId = (input) => {
      const channelMatch = input.match(/^(?:(?:https|http):\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be).*(?<=\/channel\/)(UC[\w\-]{22})(?=\?|&|$)/);
      if (channelMatch) return channelMatch[1];
      const videoMatch = input.match(/^(?:(?:https|http):\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be).*(?<=\/|v\/|u\/|embed\/|shorts\/|watch\?v=)(?<!\/user\/)([\w\-]{11})/);
      if (videoMatch) return videoMatch[1];
    }
    const updateSelectHandler = (property) => {
      return (value) => updateIdentifier(property, value.value);
    }

    function checkIfIdValid() {
      switch(identifier.platform) {
        case 'youtube_channel': return /^UC[-_\w]{22}$/.test(identifier.platform_identifier);
        case 'youtube_video': return /^[\w-]{11}$/.test(identifier.platform_identifier);
        default: return true;
      }
    }
    const isIdValid = checkIfIdValid();
    if (!identifier.destroy && !isIdValid) submittable = false;

    const exampleId = () => {
      switch(identifier.platform) {
        case 'youtube_channel': return 'UC-lHJZR3Gqxm24_Vd_AJ5Yw';
        case 'youtube_video': return 'dQw4w9WgXcQ';
      }
    };

    return (
      <fieldset className="vertical inputs-flex has_many_fields" key={index}>
        <ol>
          <li className="select input required"
              id={`${inputIdPrefix}_input`}>
            <label
              htmlFor={`${inputIdPrefix}_platform`} className="label">Type<abbr
              title="required">*</abbr>
            </label>
            <div style={{minWidth: '41%'}}>
              <Select
                name={`${inputNamePrefix}[platform]`}
                id={`${inputIdPrefix}_platform`}
                options={platforms}
                value={platformLookup[identifier.platform] || ''}
                onChange={updateSelectHandler('platform')}
                isSearchable={false}
                className='react-select'
              >
              </Select>
            </div>

          </li>
          <li className="string input required stringish"
              id={`${inputIdPrefix}_platform_identifier_input`}>
            <label htmlFor={`${inputIdPrefix}_platform_identifier`} className="label">
              {platformLookup[identifier.platform].label} ID<abbr title="required">*</abbr><br />
              <span className='info'>(Paste the ID if possible. If all you have is a URL, paste it and we will try to get the ID for you.)</span>
            </label>
            <div style={{ flexGrow: 1}}>
              <input
                id={`${inputIdPrefix}_platform_identifier`}
                name={`${inputNamePrefix}[platform_identifier]`}
                className={`w20rem ${isIdValid ? '' : 'error'}`}
                placeholder={`Example: ${exampleId(identifier.platform)}`}
                type="text"
                value={identifier.platform_identifier}
                onChange={updateIdHandler('platform_identifier')}
                data-1p-ignore
              />
              { !isIdValid && (
                <div className='error'>{`Invalid ${platformLookup[identifier.platform].label}, should look like: ${exampleId()}`}</div>
              )}
              { identifier.platform_identifierMessage && (
                <div className='info'>{identifier.platform_identifierMessage}</div>
              )}

            </div>
          </li>
          {showFullForm && (
            <>
              <li className="string input optional stringish"
                  id={`${inputIdPrefix}_aegis_id_input`}>
                <label
                  htmlFor={`${inputIdPrefix}_aegis_id`} className="label">Aegis</label>
                <input
                  id={`${inputIdPrefix}_aegis_id`}
                  name={`${inputNamePrefix}[aegis_id]`}
                  type="text"
                  value={identifier.aegis_id}
                  onChange={updateHandler('aegis_id')}
                  data-1p-ignore
                />
              </li>
            </>
          )}
          <li className="has_many_delete boolean input optional"
              id={`${inputIdPrefix}__destroy_input`}>
            <input type="hidden"
                   name={`${inputNamePrefix}[_destroy]`}
                   value="0"
                   autoComplete="off" />
            <label htmlFor={`${inputIdPrefix}__destroy`} className="">
              <input type="checkbox"
                     name={`${inputNamePrefix}[_destroy]`}
                     id={`${inputIdPrefix}__destroy`}
                     value={identifier.destroy}
                     onChange={updateHandler('destroy', 'checked')}
              />Delete
            </label>

          </li>
        </ol>
        <input autoComplete="off" type="hidden"
               value={identifier.id}
               name={`${inputNamePrefix}[id]`}
               id={`${inputIdPrefix}_id`} />
      </fieldset>
    );
  }
  const identifierUI = identifiers.map((identifier, index) => renderIdentifierForm(identifier, index))
  if(submittable) {
    document.querySelector('input[type="submit"]').disabled = false;
  } else {
    document.querySelector('input[type="submit"]').disabled = true;
  }
  return (
    <ol>
      <li className="has_many_container social_clearance_identifiers" data-sortable-start="0"><h3>Channels / Videos</h3>
        {identifierUI}
        <a className="button has_many_add_react" onClick={addIdentifier}>New Channel / Video</a>
      </li>
    </ol>
  );
};

export default SocialClearanceIdentifiersSection;