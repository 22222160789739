import * as React from "react";

const ContentStatus = {
  missing_info: "MISSING INFO",
  ready: "READY",
  pending: "PENDING",
  active: "ACTIVE",
  ownership_conflict: "OWNERSHIP CONFLICT",
  reference_overlap: "REFERENCE OVERLAP",
  invalid_reference: "INVALID REFERENCE",
  ownership_transfer_request: "OWNERSHIP TRANSFER REQUEST",
  inactive: "INACTIVE"
}

const AegisStateInput = ({currentStatus}) => {
  const renderRadios = () => {
    switch(currentStatus) {
      case ContentStatus.active:
        return (
          <ol className="radio-group">
            <li class="input optional stringish" id="content_status_input">
              <label htmlFor="content_status" className="label">Status</label>
              <div id="content_status">
                <input type="radio" value="ACTIVE" checked="checked" name="status" /> Active
                <input type="radio" value="INACTIVE" name="status" /> Inactive
              </div>
            </li>
          </ol>  
        )
      case ContentStatus.ownership_conflict:
          return (
            <ol className="radio-group">
              <li class="input optional stringish" id="content_status_input">
                <label htmlFor="content_status" className="label">Status</label>
                <div id="content_status">
                  <input type="radio" value="OWNERSHIP CONFLICT" checked="checked" name="status" /> Ownership Conflict
                  <input type="radio" value="INACTIVE" name="status" /> Release Ownership
                </div>
              </li>
            </ol>  
          )
      case ContentStatus.reference_overlap:
        return (
          <ol className="radio-group">
            <li class="input optional stringish" id="content_status_input">
              <label htmlFor="content_status" className="label">Status</label>
              <div id="content_status">
                <input type="radio" value="REFERENCE OVERLAP" checked="checked" name="status" /> Reference Overlap
                <input type="radio" value="EXCLUDE SEGMENT" name="status" /> Exclude Segment
                <input type="radio" value="INACTIVE" name="status" /> Inactive
              </div>
            </li>
          </ol>  
        )
      case ContentStatus.invalid_reference:
        return (
          <ol className="radio-group">
            <li class="input optional stringish" id="content_status_input">
              <label htmlFor="content_status" className="label">Status</label>
              <div id="content_status">
                <input type="radio" value="INVALID REFERENCE" checked="checked" name="status" /> Invalid Reference
                <input type="radio" value="EXCLUDE SEGMENT" name="status" /> Exclude Segment
              </div>
            </li>
          </ol>  
        )
      case ContentStatus.ownership_transfer_request:
        return (
          <ol className="radio-group">
            <li class="input optional stringish" id="content_status_input">
              <label htmlFor="content_status" className="label">Status</label>
              <div id="content_status">
                <input type="radio" value="OWNERSHIP TRANSFER REQUEST" checked="checked" name="status" /> Ownership Transfer Requested
                <input type="radio" value="INACTIVE" name="status" /> Release Ownership
              </div>
            </li>
          </ol>  
        )                                
      default:
        return false;
    }
  }
  return(
    <div>{renderRadios()}</div>
  );
};

export default AegisStateInput;