import $ from 'jquery';
import Clipboard from 'clipboard'; 

$(function(){ 

  var clipboard = new Clipboard('.fawkes-clipboard'); 

  clipboard.on('success', function(e) {
  });
  
  clipboard.on('error', function(e) {
  });
});  
