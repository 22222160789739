import * as React from 'react';

const SubscriptionSection = ({ subscriptionAppUrl, userEmail, accountCode }) => {
  const handleClick = () => {
    window.open(`${subscriptionAppUrl}/?email=${userEmail}&accountCode=${accountCode}`);
  };

  return (
    <div id="subscription-wrapper">
      <h2>Introducing Fawkes Rights Manager</h2>
      <p>
        Upgrade today to take control of your music catalog online. Some of the benefits of Fawkes Rights Manager
        include:
      </p>
      <ul>
        <li>
          Get out of spreadsheets and manage your catalog in an intuitive system that brings together all your metadata
          for recordings and compositions
        </li>
        <li>Export your data in industry standard formats</li>
        <li>Access to a YouTube CMS to inspect and manage your assets within YouTube Content ID</li>
        <li>
          Store your contracts alongside your data for the first time! Eliminate the need for external storage on
          dropbox and google drive.
        </li>
        <li>Shortlink services</li>
        <li>All recording, releases, compositions and artist data available via public API</li>
      </ul>
      <button onClick={handleClick}>Find out more</button>
    </div>
  );
};

export default SubscriptionSection;
