import recordingImportFactory from './data_import_recordings';
import catalogWithPerformanceRightsFactory from './catalog_with_performance_rights';

const importConfigMap = {
    'data-import-recordings': recordingImportFactory,
    'data-import-catalog-with-performance-rights': catalogWithPerformanceRightsFactory
}

function setupImportLinks() {
    document.querySelectorAll('[data-flat-file]').forEach((el) => {
        el.addEventListener('click', async (e) => {
            e.preventDefault();
            const flatFileOptions = JSON.parse(el.getAttribute('data-flat-file'));
            const flatFileContent = await fetch(`/data_imports/${flatFileOptions.importId}/payload_contents`).then((response) => response.json());
            const importerFactory = importConfigMap[flatFileOptions.type];

            if (!importerFactory) {
                console.error('Unhandled flat file type!', flatFileOptions.type, importerFactory);
                return;
            }

            const importer = importerFactory(flatFileOptions);

            importer.setCustomer(flatFileOptions.customer);
            importer.requestDataFromUser({ source: flatFileContent.data }).then(function (results) {
                importer.displayLoader('Saving import results...');

                const payload = {
                    batchId: results.batchId,
                    results: results.rawOutput,
                };

                fetch(`/data_imports/${flatFileOptions.importId}/flat_file_results`, {
                    method: 'POST',
                    mode: 'same-origin',
                    cache: 'no-store',
                    credentials: 'same-origin',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content,
                    },
                    body: JSON.stringify(payload),
                }).then((response) => {
                    const saveResult = response.json();
                    console.log('Flat File Results Saved!', saveResult);
                    importer.displaySuccess('Thanks for uploading your data!');
                });
            });
        });
    });
}

window.addEventListener('DOMContentLoaded', setupImportLinks);
