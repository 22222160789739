import * as fieldHooks from "./field_hooks";
import { proIpiRequiredHook, exampleRowHook } from "./record_hooks";
const NUM_REPEATABLE_COLUMNS = 10;

function mainArtistColumns() {
    const columns = [];
    for(let i = 0; i < NUM_REPEATABLE_COLUMNS; i++) {
        const field = {
            key: `main_artist_${i}`,
            label: `Main Artist:${i}`
        };

        // only require a main artist
        if (i === 0) {
            field.validators = [{
                validate: 'required_without',
                fields: ['artist']
            }]
        }

        columns.push(field);
    }
    return columns;
}

function featuredArtistColumns() {
    const columns = [];
    for(let i = 0; i < NUM_REPEATABLE_COLUMNS; i++) {
        const field = {
            key: `featured_artist_${i}`,
            label: `Featured Artist:${i}`
        };

        columns.push(field);
    }
    return columns;
}

function versionColumns(version) {
    const columns = [];
    for(let i = 0; i < NUM_REPEATABLE_COLUMNS; i++) {
        const field = {
            key: `${version.split(' ').join('_').toLowerCase()}_${i}`,
            label: `${version}:${i}`
        };

        columns.push(field);
    }
    return columns;
}

function shareColumns() {
    const columns = [];

    // writer columns start at 1
    for(let i = 1; i < NUM_REPEATABLE_COLUMNS + 1; i++) {
        columns.push({
            key: `writer_name_${i}`,
            label: `Writer (${i}) Full Name`,
            validators: [
                {
                    validate: 'required_with',
                    fields: [
                        `writer_share_percentage_${i}`,
                        `writer_pro_${i}`,
                        `writer_ipi_${i}`,
                        `publisher_name_${i}`,
                        `publisher_pro_${i}`,
                        `publisher_ipi_${i}`
                    ]
                }
            ]
        });

        columns.push({
            key: `writer_share_percentage_${i}`,
            label: `Writer (${i}) % Share`,
            validators: [
                {
                    validate: 'required_with',
                    fields: [
                        `writer_name_${i}`,
                        `writer_pro_${i}`,
                        `writer_ipi_${i}`,
                        `publisher_name_${i}`,
                        `publisher_pro_${i}`,
                        `publisher_ipi_${i}`
                    ],
                },
                {
                    validate: 'regex_matches',
                    regex: '^[1-9](\\.\\d+)?$|^[1-9][0-9](\\.\\d+)?$|^(100)$'
                }
            ]
        });

        columns.push({
            key: `writer_pro_${i}`,
            label: `Writer (${i}) PRO`,
            validators: [
                {
                    validate: 'required_with',
                    fields: [
                        `writer_ipi_${i}`
                    ]
                }
            ]
        });

        columns.push({
            key: `writer_ipi_${i}`,
            label: `Writer (${i}) IPI/CAE #`,
            validators: [
                {
                    validate: 'regex_matches',
                    regex: '\\d{9,11}'
                }
            ]
        });

        columns.push({
            key: `publisher_name_${i}`,
            label: `Publisher (${i}) Name`,
            validators: [
                {
                    validate: 'required_with',
                    fields: [
                        `publisher_pro_${i}`,
                        `publisher_ipi_${i}`
                    ]
                }
            ]
        });

        columns.push({
            key: `publisher_pro_${i}`,
            label: `Publisher (${i}) PRO`,
            validators: [
                {
                    validate: 'required_with',
                    fields: [
                        `publisher_ipi_${i}`
                    ]
                }
            ]
        });

        columns.push({
            key: `publisher_ipi_${i}`,
            label: `Publisher (${i}) IPI/CAE #`,
            validators: [
                {
                    validate: 'regex_matches',
                    regex: '\\d{9,11}'
                }
            ]
        });

        columns.push({
            key: `controlled_${i}`,
            label: `Controlled ${i}`
        })
    }

    return columns;
}

export default function(options) {
    const importer = new FlatfileImporter(window.flatfileConfig.licenseKey, {
        type: 'data-import-catalog-with-performance-rights',
        title: 'Catalog With Performance Rights',
        managed: true,
        devMode: options.devMode,
        fields: [
            {
                key: 'audio_filename',
                label: 'Audio Filename'
            },
            {
                key: 'artwork_filename',
                label: 'Artwork Filename'
            },
            {
                key: 'album',
                label: 'Album'
            },
            {
                key: 'artist',
                label: 'Artist',
                validators: [
                    { validate: 'required_without', fields: ['main_artist_0'] }
                ]
            },
            {
                key: 'master_owner',
                label: 'Master Owner(s)',
                validators: [
                    { validate: 'required' }
                ]
            },
            {
                key: 'master_owner_share_percentage',
                label: 'Master Owner Share %',
                validators: [
                    { validate: 'required' }
                ]
            },
            ...mainArtistColumns(),
            ...featuredArtistColumns(),
            {
                key: 'title',
                label: 'Title',
                alternates: ['Song Title'],
                validators: [
                    {validate: 'required'}
                ]
            },
            ...versionColumns("Alternate Version"),
            ...versionColumns("Instrumental"),
            {
                key: 'isrc',
                label: 'ISRC',
                validators: [
                    {validate: 'unique'},
                    {
                        validate: 'regex_matches',
                        regex: '^[a-zA-Z]{2}[a-zA-Z0-9]{3}\\d{7}(\\|[a-zA-Z]{2}[a-zA-Z0-9]{3}\\d{7})*$',
                        error:
                            'An ISRC must start with 2 letters, followed by a 3 letter and digits code, followed by a 2 digit year, followed by a 5 digit sequence number (e.g. SMIJA1400054 ). Multiple ISRCs can be separated with a pipe ( | ) character (e.g. SMIJA1400054|SMIJA1400155).',
                    },
                ]
            },
            {
                key: 'iswc',
                label: 'ISWC',
                validators: [
                    {
                        validate: 'regex_matches',
                        regex: '^T\\d{9}\\d(\\|T\\d{9}\\d)*$',
                        error:
                            'AN ISWC must start with a T and be followed by 10 digits (e.g. T1142423481). Multiple ISWCs should be separated by a pipe ( | ) character (e.g. T1142423481|T1142423482).',
                    },
                ]
            },
            {
                key: 'vocal_type',
                label: 'Vocal Type'
            },
            ...shareColumns()
        ]
    });

    importer.registerFieldHook('isrc', fieldHooks.dedasher);
    importer.registerFieldHook('iswc', fieldHooks.dedasher);

    importer.registerRecordHook(record => {
        return {
            ...exampleRowHook(record),
            ...proIpiRequiredHook(record)
        }
    });

    importer.options.fields.forEach((field) => {
        importer.registerFieldHook(field.key, fieldHooks.trimmer);
    });

    return importer;
}