import $ from 'jquery';
import 'select2';
import 'select2/dist/css/select2.css';
import './searchable_select.scss';

(function() {
  // Ensure Select2 dropdown opens when focus-ing with tab. Based on https://stackoverflow.com/questions/20989458/select2-open-dropdown-on-focus
  // on first focus (bubbles up to document), open the menu
  $(document).on('focus', '.select2-selection.select2-selection--single', function (e) {
    $(this).closest(".select2-container").siblings('select:enabled').select2('open');
  });

  // steal focus during close - only capture once and stop propogation
  $('select.select2').on('select2:closing', function (e) {
    $(e.target).data("select2").$selection.one('focus focusin', function (e) {
      e.stopPropagation();
    });
  });

  function initSearchableSelects(inputs, extra) {

    inputs.each(function() {
      var item = $(this);

      // reading from data allows <input data-searchable_select='{"tags": ['some']}'>
      // to be passed to select2
      var options = $.extend(extra || {}, item.data('searchableSelect'));
      var url = item.data('ajaxUrl');

      if (url) {
        $.extend(options, {
          ajax: {
            url: url,
            dataType: 'json',

            data: function (params) {
              return {
                term: params.term,
                page: pageParamWithBaseZero(params)
              };
            }
          }
        });
      }

      item.select2(options);
    });
  }

  function pageParamWithBaseZero(params) {
    return params.page ? params.page - 1 : undefined;
  }

  $(document).on('has_many_add:after', '.has_many_container', function(e, fieldset) {
    initSearchableSelects(fieldset.find('.searchable-select-input'));
  });

  $(document).on('page:load turbolinks:load', function() {
    initSearchableSelects($(".searchable-select-input"), {placeholder: ""});
  });

  $(function() {
    initSearchableSelects($(".searchable-select-input"));
  });
}());