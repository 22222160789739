$(function() { 
  if($.trim($('#recording_title').val()).length === 0) {
    $('#recording_submit_action input').prop('disabled', true);
  } 

  $('#recording_title').on("keyup", function(event) {
    if($.trim(event.target.value).length === 0) {
      $('#recording_submit_action input').prop('disabled', true);
    } else {
      $('#recording_submit_action input').prop('disabled', false);
    }
  })
})
