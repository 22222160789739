import * as fieldHooks from "./field_hooks";

export default function(options) {
    const importer = new FlatfileImporter(window.flatfileConfig.licenseKey, {
        type: 'data-import-recordings',
        title: 'Data Import - Recordings',
        managed: true,
        devMode: options.devMode,
        fields: [
            {
                key: 'id',
                label: 'Id',
                validators: [
                    {validate: 'required'},
                    {validate: 'unique'}
                ]
            },
            {
                key: 'title',
                label: 'Title',
                validators: [
                    {validate: 'required'}
                ]
            },
            {key: 'artist', label: 'Artist'},
            {
                key: 'isrc',
                label: 'ISRC',
                validators: [
                    {validate: 'required'},
                    {validate: 'unique'},
                    {
                        validate: 'regex_matches',
                        regex: '^[a-zA-Z]{2}[a-zA-Z0-9]{3}\\d{7}(\\|[a-zA-Z]{2}[a-zA-Z0-9]{3}\\d{7})*$',
                        error:
                            'An ISRC must start with 2 letters, followed by a 3 letter and digits code, followed by a 2 digit year, followed by a 5 digit sequence number (e.g. SMIJA1400054 ). Multiple ISRCs can be separated with a pipe ( | ) character (e.g. SMIJA1400054|SMIJA1400155).',
                    },
                ]
            },
            {
                key: 'parental_advisory',
                label: 'Parental Advisory',
                validators: []
            },
            {
                key: 'release',
                label: 'Release',
                validators: []
            },
            {
                key: 'release_type',
                label: 'Release Type',
                validators: [
                    {
                        validate: 'required_with',
                        fields: ['release']
                    }
                ]
            },
            {
                key: 'sequence',
                label: 'Sequence',
                validators: [
                    {
                        validate: 'required_with',
                        fields: ['release']
                    }
                ]
            }
        ]
    });

    importer.registerFieldHook('isrc', fieldHooks.dedasher);

    importer.options.fields.forEach((field) => {
        importer.registerFieldHook(field.key, fieldHooks.trimmer);
    });

    return importer;
}