// Load Active Admin's styles into Webpacker,
// see `active_admin.scss` for customization.
import "../stylesheets/active_admin";

import "@activeadmin/activeadmin";

import "../components/searchable_select/init"
import "../components/form_toggle/init"
import "../components/array_input/init"
import "../components/submission_forms/init"
import "../components/uploader/init"
import "../components/external_identifiers/init"
import "../components/edit_file/init"
import "../components/flashes/init"
import "../components/export_select/init"
import "../flat_file/init"
// import "../components/clipboard/init"

var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);