
function setupExportSelect() {
    $(".export-select").on('change', (e) => {

        let settingsOptions;
        switch(e.target.value) {
            default:
                settingsOptions = "";
        }

        // remove existing settings
        $("li.export-setting").remove();

        // add new ones
        $("fieldset.inputs ol").append(settingsOptions);
    });
}

window.addEventListener('DOMContentLoaded', setupExportSelect);
