export function proIpiRequiredHook(record) {
    const out = {}
    const pro_keys = Object.keys(record).filter((key) => key.includes('_pro_')); // will include both writer_pro and publisher_pro
    for (let pro_key of pro_keys) {
        const ipi_key = pro_key.replace('_pro_', '_ipi_');

        const pro = record[pro_key]?.trim();
        if (!pro || pro === "NS") {
            continue;
        }

        const ipi = record[ipi_key].trim();
        if (!ipi) {
            out[ipi_key] = {
                info: [
                    {
                        message: 'IPI required when PRO is present and not NS',
                        level: 'error'
                    }
                ]
            }
        }
    }

    return out;
}

const TEMPLATE_INSTRUCTION = "Exact filename essential for bulk uploading";
const TEMPLATE_EXAMPLE = "01_come_together.wav";
export function exampleRowHook(record) {
    if(record.audio_filename === TEMPLATE_INSTRUCTION || record.audio_filename === TEMPLATE_EXAMPLE) {
        return Object.keys(record).reduce((acc, key) => {
            acc[key] = {
                info: [
                    {
                        message: 'This looks like an example/description row and should be deleted/ignored',
                        level: 'error'
                    }
                ]
            }
            return acc;
        }, {});
    }

    return {};
}