import $ from 'jquery';
import { findParent } from '../../helpers/dom';

$(function () {
  $(document).on('input', '[data-types-can-generate]', function (e) {
    updateIdentifier(e.target);
  });

  $(document).on('click', '.has_many_add', function (e) {
    updateExistingEls();
    $(this).closest('fieldset').find('.info-panel').removeClass('hidden'); 
  });

  function updateExistingEls() {
      document.querySelectorAll('[data-types-can-generate]').forEach((el) => updateIdentifier(el));
  }

  updateExistingEls();

  function updateIdentifier(el) {
    const canGeneratePrefixes = JSON.parse(el.getAttribute('data-types-can-generate'));
    let canGenerate = false;
    for (const p of canGeneratePrefixes) {
      if (el.value.indexOf(p) === 0) {
        canGenerate = true;
        break;
      }
    }

    const fieldset = findParent(el, 'fieldset.has_many_fields');
    const shouldGenerateWrapper = fieldset.querySelector('li.should_generate');
    if (shouldGenerateWrapper) {
      const shouldGenerateInput = shouldGenerateWrapper.querySelector('input[type="checkbox"]');
      const uidInput = fieldset.querySelector('.uid input');

      if (uidInput) 
      {
        if (canGenerate) {
          if (shouldGenerateWrapper.classList.contains('hidden')) {
            shouldGenerateWrapper.classList.remove('hidden');
            shouldGenerateInput.checked = !uidInput.value;
            inputState(uidInput, !uidInput.value);
          }
        } else {
          shouldGenerateWrapper.classList.add('hidden');
          shouldGenerateInput.checked = false;
          inputState(uidInput, false);
        }
      }
    }
  }

  $(document).on('change', '.should_generate', function (e) {
    updateUidOnGenerate(e.target);
  });

  function updateUidOnGenerate(el) {
    const fieldset = findParent(el, 'fieldset.has_many_fields');
    const uidInput = fieldset.querySelector('.uid input');

    inputState(uidInput, el.checked);
  }

  function inputState(input, disabled) {
    if (input) 
    {
      if (disabled) {
        input.classList.add('strike');
        input.disabled = true;
      } else {
        input.classList.remove('strike');
        input.disabled = false;
      }
    }
  }
});
