import * as React from "react";
import {useState} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const PerpetualOrDateRangeInput = ({perpetual, start, end}) => {
  const [isPerpetual, setPerpetual] = useState(perpetual);
  const [startDate, setStartDate] = useState(start ? new Date(start): undefined);
  const [endDate, setEndDate] = useState(end ? new Date(end): undefined);

  return (
    <>
      <label htmlFor="license_period" className="label">License Period</label>
      <div className="form-right" id="license_period">
        <div style={{ lineHeight: '2rem', paddingTop: '0.25rem'}}>
          <input type="checkbox" value="PERPETUAL" checked={isPerpetual} onChange={() => setPerpetual(!isPerpetual)} /> In Perpetuity
        </div>
        <div style={{ display: isPerpetual ? 'none' : 'block' }}>
          <span style={{ display: 'inline-block', width: '120px', fontWeight: 'bold' }}>Start Date</span>
          <DatePicker
            selected={startDate}
            minDate={Date.now()}
            maxDate={endDate}
            showPopperArrow={false}
            onChange={(date) => setStartDate(date)} />
          <input
            id="social_clearance_start_date"
            type="hidden"
            name="social_clearance[start_date]"
            value={isPerpetual ? '' : startDate?.toISOString()}
          />
          <span style={{ display: 'inline-block', width: '120px', fontWeight: 'bold' }}>End Date</span>
          <DatePicker
            selected={endDate}
            minDate={startDate || Date.now()}
            showPopperArrow={false}
            onChange={(date) => setEndDate(date)} />
          <input
            id="social_clearance_end_date"
            data-datepicker-options="{}"
            type="hidden"
            name="social_clearance[end_date]"
            value={isPerpetual ? '' : endDate?.toISOString()}
          />
        </div>
      </div>
    </>
  )
};

export default PerpetualOrDateRangeInput;